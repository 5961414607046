
























import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Characters } from '@/constants/char';
import { Fraction } from '@/helper/helperIndex';

@Component({
    components: {
        Roller: () => import('vue-roller'),
        FirstLook: () => import('@/components/FirstLook.vue'),
        WorkLook: () => import('@/components/WorkLook.vue'),
        ProjectLook: () => import('@/components/ProjectLook.vue'),
        SkillsTicker: () => import('@/components/SkillsTicker.vue'),
        Intro: () => import('@/components/Intro.vue'),
        TitleEffect: () => import('@/components/utils/TitleEffect.vue'),
        Card: () => import('@/components/utils/Card.vue'),
        InfiniteScrollBar: () => import('@/helper/InfiniteScroll.vue'),
    },
})
export default class HomePage extends Vue {
    @Prop() handleScrollVariable: any;
    scrollVariable: number = 0;

    @Emit('handleScrollVariable')
    handleScroll(variable: any) {}

    //for title sliding in
    get titleSpeed(): number {
        let constant: number = 862;
        let number = constant - this.scrollVariable;
        return number > 0 ? number : 0;
    }
    // for roller
    get charList(): string[] {
        let capsCharList = Characters.map(char => (char = char?.toUpperCase()));
        let specialChar = ['.', '-'];
        let charList: string[] = [];
        charList.push(...Characters, ...capsCharList, ...specialChar, ' ');
        return charList;
    }
    onScroll(event: any) {
        this.scrollVariable = event.target.scrollTop;
        this.handleScroll(this.scrollVariable);
    }

    radialEffect(start: number, end: number) {
        let fraction = Fraction(start, end, this.scrollVariable);
        return `radial-gradient(circle at ${200 -
            fraction * 100}%, rgba(255, 71, 255, 0.527) 5%, #eee 35%, #121212 35%)`;
    }
}
